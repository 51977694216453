<template>
  <MenuComponent  :selected-keys="$route.name">
    <template #content>
      <router-view :key="$route.fullPath"/>
    </template>
  </MenuComponent>
</template>

<script>
import MenuComponent from "@/components/main/MenuComponent";

export default {
  name: "PersonalCabinetPage",
  components: {MenuComponent},
}
</script>

<style scoped>

</style>