import {createStore} from 'vuex'
import {user} from "./user";
import {application} from "./application";
import {admin} from "@/store/admin";
import {socket} from "@/store/socket";
import {events} from "@/store/events";


// creating Vuex store instance
const store = createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user, application, admin, socket, events
    }
});

//store.dispatch('loadUserToken');

export default store;