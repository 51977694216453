<template>

  <a-modal :title="`Создание нового пользователя`" v-if="viewModal"
           :visible="viewModal" ok-text="Создать"
           cancel-text="Отмена" @cancel="closeModal" @ok="createNewUser">
    <UserCreation ref="userCreation" v-if="creationUserRole" :role="creationUserRole"></UserCreation>
  </a-modal>


  <div class="container" v-if="usersList">
    <div class="params-table-container">
      <div class="buttons-container">
        <a-button type="primary" @click="openModal('admin')">Добавить администратора</a-button>
        <a-button type="primary" ghost @click="openModal('lecture')">Добавить лектора</a-button>
<!--        <a-button type="primary" ghost @click="openModal('guide')">Добавить экскурсовода</a-button>-->

      </div>
      <a-select v-model:value="roles" style="width: 170px" placeholder="Показать">
        <a-select-option value="users">Пользователи</a-select-option>
        <a-select-option value="admins">Администраторы</a-select-option>
      </a-select>
    </div>

    <a-table v-if="roles==='users'" :custom-row="customRow" :dataSource="usersList" :columns="columns">
      <template #bodyCell="{column,record}">
        <div class="status-tags" v-if="column.key==='status'">
          <a-tag color="success" v-if="record.status==='available'">Активный</a-tag>
          <a-tag color="processing" v-if="record.status==='unapproved'">Не подтвержден</a-tag>
          <a-tag color="error" v-if="record.status==='deleted'">Удален</a-tag>
          <a-tag color="warning" v-if="record.status==='banned'">В блокировке</a-tag>
          <a-tag color="cyan" v-if="record.status==='chat_blocked'">Чат заблокирован</a-tag>
        </div>
        <div class="" v-if="column.key==='registerDate'">
          {{ record.registerDate ? new Date(record.registerDate).toLocaleDateString('ru-RU', dateOptions) : '' }}
        </div>
        <div class="" v-if="column.key==='role'">
          {{
            record.role === 'lecture' ? 'Ведущий' : record.role === 'admin' ? 'Администратор' : record.role === 'guide' ? 'Экскурсовод' : record.role === 'exhibit' ? 'Экспонат' : 'Пользователь'
          }}
        </div>

      </template>
      <template #customFilterDropdown="{column,confirm}">
        <div class="filters-container" v-if="column.key==='registerDate'">
          <div class="filters-el">
            <h4>От: </h4>
            <a-date-picker :locale="locale" v-model:value="from"/>
          </div>
          <div class="filters-el">
            <h4>До: </h4>
            <a-date-picker :locale="locale" v-model:value="to"/>
          </div>
          <div class="filters-el">
            <h4>Сортировка по датам: </h4>
            <a-select placeholder="Сортировка" v-model:value="sort">
              <a-select-option value="dec">
                Сначала новые
              </a-select-option>
              <a-select-option value="inc">
                Сначала старые
              </a-select-option>
            </a-select>
          </div>

          <div class="filters-buttons">
            <a-button
                @click="fetchUsersList(confirm)"
                type="primary">
              Найти
            </a-button>
            <a-button @click="dropFilters(confirm)">
              Сбросить
            </a-button>
          </div>
        </div>
        <div class="filters-container" v-if="column.key==='name'">
          <div class="filters-el">
            <h4>Поиск по имени: </h4>
            <a-input v-model:value="searchName" placeholder="ФИО" @keydown.enter="fetchUsersList(confirm)"></a-input>
          </div>

          <div class="filters-buttons">
            <a-button
                @click="fetchUsersList(confirm)"
                type="primary">
              Найти
            </a-button>
            <a-button @click="dropFilters(confirm)">
              Сбросить
            </a-button>
          </div>
        </div>
      </template>

    </a-table>
    <a-table v-if="roles==='admins'" :dataSource="usersList" :columns="adminColumns"></a-table>
  </div>
</template>

<script>
import {errorHandler} from "@/lib/errorHandler";
import locale from "ant-design-vue/es/date-picker/locale/ru_RU"
import dayjs from "dayjs";
import 'dayjs/locale/ru';
import UserCreation from "@/components/UserCreation.vue";

export default {
  name: "UsersListPage",
  components: {
    UserCreation,
  },
  computed: {
    usersList() {
      return this.$store.getters.usersList;
    }
  },
  data() {
    return {

      columns: [
        {
          title: 'ФИО',
          dataIndex: 'name',
          key: 'name',
          customFilterDropdown: true
        },
        {
          title: 'Номер телефона',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Роль',
          dataIndex: 'role',
          key: 'role',
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Дата регистрации',
          dataIndex: 'registerDate',
          key: 'registerDate',
          customFilterDropdown: true
        },
      ],

      adminColumns: [
        {
          title: 'ФИО',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Логин',
          dataIndex: 'login',
          key: 'login',
        },
      ],

      newAdmin: null,
      newLecture: null,

      viewModal: false,
      dateOptions: {
        month: "long",
        day: "numeric",
        year: "numeric"
      },

      page: 1,
      limit: 500,
      to: null,
      from: null,
      sort: 'dec',
      locale,
      dayjs,


      roles: 'users',

      searchName: null,

      creationUserRole: null,

    }
  },
  methods: {

    createNewUser() {
      const user = this.$refs?.userCreation?.userData;

      if (user) {

        if(this.creationUserRole ==='admin'){
          if (!user.name) {
            errorHandler({name: 'validation', meta: {name: 'name'}});
            return
          }
          if (!user.login) {
            errorHandler({name: 'validation', meta: {name: 'login'}});
            return
          }
          if (!user.password) {
            errorHandler({name: 'validation', meta: {name: 'password'}});
            return
          }

          this.$store.dispatch('createAdmin', user).then((status) => {
            if (status === 200) {
              this.closeModal();
            }
          });
        }

        if(this.creationUserRole ==='guide'|| this.creationUserRole==='lecture'){

          if (!user.name) {
            errorHandler({name: 'validation', meta: {name: 'name'}});
            return
          }
          if (!user.email || !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(user.email)) {
            errorHandler({name: 'validation', meta: {name: 'email'}});
            return
          }
          if (!user.phone || user.phone.length < 10) {
            errorHandler({name: 'validation', meta: {name: 'phone'}});
            return
          }
          if (!user.password || !(user.password.length > 10 && /\W/.test(user.password) && /[0-9]/.test(user.password) && /[A-Z]|[А-Я]/.test(user.password) && /[a-z]|[а-я]/.test(user.password))) {
            errorHandler({name: 'validation', meta: {name: 'password'}});
            return
          }

          this.$store.dispatch('createLecture', {
            name: user.name,
            email: user.email,
            phone: user.phone,
            password: user.password
          }).then((status) => {
            if (status === 200) {
              this.closeModal();
            }
          });

        }

      }

    },
    customRow(record) {
      return {
        style: {
          cursor: 'pointer'
        },
        onclick: () => {
          this.$router.push({name: 'separateUser', params: {id: record.id}});
        }
      }
    },
    openModal(role) {
      this.creationUserRole = role;
      this.viewModal = true;
    },
    closeModal() {
      this.viewModal = false;
      this.creationUserRole = null;
    },
    dropFilters(confirm) {
      this.sort = 'dec';
      this.to = null;
      this.from = null;
      this.page = 1;
      this.searchName = null;
      this.fetchUsersList(confirm);
    },
    fetchUsersList(confirm) {
      this.$store.dispatch('fetchUsersList', {
        page: this.page,
        limit: this.limit,
        to: this.to ? this.dayjs(this.to).toDate().toUTCString() : '',
        from: this.from ? this.dayjs(this.from).toDate().toUTCString() : '',
        name: this.searchName ? this.searchName : '',
        sort: this.sort
      }).then(() => {
        if (confirm) {
          confirm();
        }
      });
    },
    fetchAdminsList() {
      this.$store.dispatch('fetchAdminList', {
        page: this.page,
        limit: this.limit
      })
    },

  },
  beforeMount() {
    this.dayjs.locale('ru');
    this.fetchUsersList();
  },
  watch: {
    roles(val) {
      this.$store.commit('clearTableList')
      if (val === 'users') {
        this.fetchUsersList()
      }
      if (val === 'admins') {
        this.fetchAdminsList()
      }
    }
  }
}
</script>

<style scoped>


.params-table-container {
  display: flex;
  margin: 20px 0;
  justify-content: space-between;
}

.buttons-container {
  display: flex;
  gap: 15px;
}

.filters-container {
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filters-el {
  width: 170px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.filters-buttons {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin: 10px 0;
}


</style>