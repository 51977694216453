import Cookies from 'js-cookie';
import {
    createAdmin, createLecture, downloadCSV,
    fetchUserData,
    login, updateAdminData,
} from "./serverInterationMethods";
import {errorHandler} from "@/lib/errorHandler";


// store with all authenticated user data
export const user = {
    state: {
        // authorization user token for api
        token: null,
        user: null
    },
    mutations: {
        // setting token state
        setToken(state, data) {
            state.token = data;
        },
        setUserData(state, data) {
            state.user = data;
        }
    },
    actions: {
        async downloadCSV({getters}) {
            const {data} = await downloadCSV(getters.token);
            const el = document.createElement(`a`);
            el.download = 'FormsData.csv';
            el.href = URL.createObjectURL(data)
            el.click();

        },
        async createAdmin({getters}, admin) {
            const {status} = await createAdmin(getters.token, admin);
            errorHandler({status, name: 'create'});
            return status;
        },

        async createLecture({getters}, lecture) {
            const {status, data} = await createLecture(getters.token, lecture);
            errorHandler({status, name: 'create', meta: {data}});
            return status;
        },

        async updateAdminData({getters}, admin) {
            let saveData = {};
            if (admin.login !== getters.user.login) {
                saveData['login'] = admin.login;
            }
            if (admin.name !== getters.user.name) {
                saveData['name'] = admin.name;
            }
            if (admin.password) {
                saveData['password'] = admin.password;
            }
            const {status} = await updateAdminData(getters.token, saveData);
            errorHandler({status, name: 'update'})
        },

        async fetchUserData({getters, commit}) {
            const {data} = await fetchUserData(getters.token);
            commit('setUserData', data)
        },
        // gets user auth token from cookies if needed
        async loadToken({getters, commit}) {
            if (getters.token) return;
            let token = Cookies.get('token');
            if (token) {
                commit('setToken', token);
            }
        },
        // default user authorization method. Saves token in cookies if success
        async login({getters, commit}, data) {
            if (getters.token) return;
            let response = await login(data);
            if (response.status === 200) {
                Cookies.set('token', response.data.token);
                commit('setToken', response.data.token);
            }
            errorHandler({status: response.status, name: 'login', meta: {data: response.data}})

        },
        // default logout method. Clear auth token from Storage (cookies by default)
        logout({commit}) {
            Cookies.remove('token');
            commit('setToken', null);
            errorHandler({name: 'logout'})
        },
    },
    getters: {
        // get default user auth token
        token: (state) => state.token,
        user: state => state.user
    }
};