import {
    deleteExhibitionPavilion,
    fetchCurrentEvent,
    fetchEvents,
    removeEventById,
    fetchExhibitionPavilions,
    saveEvent,
    saveExhibits,
    toggleDisableSlot,
    fetchAveleksFeedback
} from "@/store/events/serverInterationMethods";
import dayjs from "dayjs";
import { errorHandler } from "@/lib/errorHandler";

const getEventType = () => {
    return sessionStorage.getItem('eventType')
        ? sessionStorage.getItem('eventType')
        : 'lecture'; // lecture | excursion | broadcast
}

export const events = {
    actions: {
        async saveExhibits({ getters }, data) {
            const { status } = await saveExhibits(getters.token, data);
            errorHandler({ status, name: 'saveExhibits' })
            return status;
        },
        async toggleDisableSlot({ getters }, slots) {
            const { data, status } = await toggleDisableSlot(getters.token, slots);

            errorHandler({ status, name: 'saveExhibits' })

            return data;
        },

        async fetchExhibitionPavilions({ getters }, eventId) {
            if (eventId === 'new') {
                return [];
            }
            const { data } = await fetchExhibitionPavilions(getters.token, eventId);
            return data;
        },

        async deleteSlot({ getters }, id) {
            const { status } = await deleteExhibitionPavilion(getters.token, id);
            return status;
        },

        async fetchAveleksFeedback({ getters, commit }, { page, limit }) {
            const { data } = await fetchAveleksFeedback(getters.token, page, limit);

            if (!data) return {
                total: 0,
                pages: 1,
                perPage: 10,
                data: [],
            };

            commit('setAveleksFeedback', { data: data ?? {
                total: 0,
                pages: 1,
                perPage: 10,
                data: [],
            }, page });
            return data;
        },

        async fetchEvents({ getters, commit }, { page, limit, type }) {
            const { data } = await fetchEvents(getters.token, page, limit, type);

            if (!data) return [];

            commit('setEvents', { data: Array.isArray(data) ? data : [], page });
            return data;
        },
        async removeEventById({ getters }, id) {
            const { data, status } = await removeEventById(getters.token, id);

            errorHandler({ name: 'removeEvent', status, msg: data.msg ?? '' })
        },

        async fetchCurrentEvent({ getters, commit }, id) {
            let data;
            if (id === 'new') {
                data = {
                    id: null,
                    title: null,
                    eventDate: null,
                    eventDateStart: dayjs(),
                    eventDateEnd: dayjs(),
                    description: null,
                    previewUrl: null,
                    eventPlace: null,
                    mapPath: null,
                    participantsLimit: null,
                    type: getEventType(),
                    eventSlots: null,
                    slotLimit: null,
                    lectorId: null
                }
            } else {
                const response = await fetchCurrentEvent(getters.token, id);
                const slotLimit = response.data[0].eventSlots?.availableSlots?.[0]?.limit ?? 10;
                data = {
                    ...response.data[0], slotLimit };
            }
            commit('setCurrentEvents', data);

            return data;
        },
        async saveEvent({ state, getters, commit }) {
            commit('changeFieldsNames');


            if (state.currentEvent?.eventSlots?.availableSlots) {
                delete state.currentEvent.eventSlots.availableSlots
            }

            const { status, data } = await saveEvent(getters.token, getters.currentEvent);


            if (data.eventSlots?.duration && data.id) {
                errorHandler({ name: 'slotsSettings', id: data.id })
            } else {
                console.log({  status, data })
                errorHandler({ name: 'create', status, relocate: 'eventsList' })
            }
        },
    },
    mutations: {
        changeFieldsNames(state) {
            if (state.currentEvent.type !== 'recordedTour') {
                state.currentEvent['lectorId'] = state.currentEvent?.leaderId;
            }
            delete state.currentEvent?.leaderId;
        },
        setEvents(state, { data, page }) {
            if (page > 1) {
                state.events = [...state.events, ...data];
            } else {
                state.events = data;
            }
        },
        setAveleksFeedback(state, { data, page }) {
            if (page > 1) {
                state.aveleksFeedback = {
                    ...data,
                    data: [
                        ...state.aveleksFeedback.data,
                        ...data.data,
                    ]
                }
            } else {
                state.aveleksFeedback = data;
            }
        },
        setCurrentEvents(state, data) {
            data.eventDateStart ? data.eventDateStart = dayjs(data.eventDateStart) : data.eventDateStart;
            data.eventDateEnd ? data.eventDateEnd = dayjs(data.eventDateEnd) : data.eventDateEnd;
            state.currentEvent = data;
        },
        setEventData(state, { val, field }) {
            if (field === 'eventDate') val ? val = dayjs(val) : val;
            state.currentEvent[field] = val;
        },
        setEventDataEventSlots(state, { val, field }) {
            if (!state.currentEvent.eventSlots) {
                state.currentEvent.eventSlots = {
                    startOfWorkingDay: 8,
                    endOfWorkingDay: 17,
                };
            }

            state.currentEvent.eventSlots[field] = val;
        },
        clearCurrentEvent(state) {
            state.currentEvent = null;
        }
    },
    state: {
        events: [],
        aveleksFeedback: {
            total: 0,
            pages: 1,
            perPage: 10,
            data: [],
        },
        currentEvent: null
    },
    getters: {
        events: state => state.events,
        aveleksFeedback: state => state.aveleksFeedback,
        currentEvent: state => state.currentEvent
    }
}