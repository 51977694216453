<script>
import {IMaskComponent} from "vue-imask";

export default {
  props: ['role'],

  name: "UserCreation",

  components: {IMaskComponent},

  data() {
    return {
      userData: null,

      mask: "0(000)000-00-00",

      maskedPhone: null,
    }
  },
  methods: {
    generatePassword(len) {
      let password = "";
      let symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!;%:?*()_+=";

      for (let i = 0; i < len; i++) {
        password += symbols.charAt(Math.floor(Math.random() * symbols.length));
      }

      if (password.length > 10 && /\W/.test(password) && /[0-9]/.test(password) && /[A-Z]|[А-Я]/.test(password) && /[a-z]|[а-я]/.test(password)) {
        this.userData.password = password;
      } else {
        const ctx = this;
        ctx.generatePassword(len);
      }
    },

  },

  beforeMount() {
    if (this.role === 'admin') {
      this.userData = {
        login: null,
        password: null,
        name: null
      }
    }
    if (this.role === 'lecture' || this.role === 'guide') {
      this.userData = {
        name: null,
        email: null,
        phone: null,
        password: null
      }
    }


    this.generatePassword(12);
  }
}
</script>

<template>
  <div class="usr-fields new-user">
    <div class="usr-fields-el" v-if=" 'name' in userData">
      <a-typography-text>ФИО:</a-typography-text>
      <a-input placeholder="ФИО" v-model:value="userData.name"></a-input>
    </div>

    <div class="usr-fields-el" v-if="'login' in userData">
      <a-typography-text>Логин:</a-typography-text>
      <a-input placeholder="Email" v-model:value.trim="userData.login"></a-input>
    </div>

    <div class="usr-fields-el" v-if="'email' in userData">
      <a-typography-text>Email:</a-typography-text>
      <a-input placeholder="Email" v-model:value.trim="userData.email"></a-input>
    </div>
    <div class="usr-fields-el" v-if="'phone' in userData">
      <a-typography-text>Номер телефона:</a-typography-text>
      <i-mask-component ref="phone"
                        placeholder="Номер телефона"
                        v-model="maskedPhone"
                        v-model:unmasked="userData.phone"
                        :mask="mask"></i-mask-component>
      <!--        <input :value="userData.phone" @input="phoneMasked" type="text">-->
      <!--        <a-input placeholder="Номер телефона" @input="phoneMasked" type="text"-->
      <!--                 v-model:unmasked="unmaskedPhone" v-model:value.trim="userData.phone"></a-input>-->
    </div>

    <div class="usr-fields-el" v-if="'password' in userData">
      <a-typography-text>Пароль:</a-typography-text>
      <a-input-password placeholder="Пароль" v-model:value.trim="userData.password"></a-input-password>
    </div>

  </div>
</template>

<style scoped>

.usr-fields {
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 15px;
}

.usr-fields-el {
  display: flex;
  flex-direction: column;
  gap: 5px
}

.new-user {
  gap: 10px;
}
</style>