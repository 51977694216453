<script setup>
import { computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex';
const { getters, dispatch } = useStore();


const route = useRoute();
const router = useRouter();

const aveleksFeedback = computed(() => getters.aveleksFeedback.data?.map((f, index) => ({
  ...f,
  number: index + 1,
  name: f.client.name,
  email: f.client.email,
})))

const page = computed(() => Number(route.query?.page ?? 1));
const limit = computed(() => Number(route.query?.limit ?? 10));

const pagination = computed(() => ({
  total: getters.aveleksFeedback.total,
  current: page.value,
  pageSize: limit.value,
}));



onMounted(() => {
  dispatch('fetchAveleksFeedback', { page: page.value, limit: limit.value })
});


const handlePageChange = ({current}) => {
  router.push({
    name: 'aveleks-feedback',
    query: {
      page: current,
      limit: pagination.value.pageSize,
    }
  })
};


const columns = [
  {
    title: '№',
    dataIndex: 'number',
  },
  {
    title: 'Имя',
    dataIndex: 'name',
   },
   {
    title: 'Email',
    dataIndex: 'email',
   },
   {
    title: 'Рейтинг',
    dataIndex: 'rating',
   },
   {
    title: 'Какой раздел экскурсии понравился вам больше всего?',
    dataIndex: 'level',
   },
   {
    title: 'Порекомендовали бы вы прохождение экскурсии вашим друзьям?',
    dataIndex: 'recommendation',
   },
   {
    title: 'Что не понравилось?',
    dataIndex: 'likes',
   },
   {
    title: 'Что помешало прохождению экскурсии?',
    dataIndex: 'interference',
   },
];

</script>

<template>
  <div>
    <a-table
      :dataSource="aveleksFeedback"
      :columns="columns"
      :pagination="pagination"
      @change="handlePageChange"
    />
  </div>
</template>



<style lang="scss" scoped>

</style>