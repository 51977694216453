export function convertTimeInMinuteAndSeconds(time) {
    let hours = Math.floor(time / 60 / 60);

    let minutes = Math.floor(time / 60) - (hours * 60);

    let seconds = time % 60;

    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes) + ':' + (seconds < 10 ? '0' + seconds : seconds);
}

export function ageCalculation(birth) {
    if (birth === 'null') {
        return 'Неопределенно'
    }
    let today = new Date();
    let birthDay = new Date(birth)
    const year = 31536000000;
    return (((today - birthDay) / year).toFixed()) + ' лет';
}

export const dateOptionsFull = {
    month: "long",
    day: "numeric",
    year: 'numeric',
}