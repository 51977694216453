<script>
export default {
  name: "NotFoundPage",
  beforeMount() {
    this.$router.push({name: 'authorization'})
  }
}
</script>

<template>
  <div class=""></div>
</template>

<style scoped>

</style>