<script>
import {createVNode} from "vue";
import {LoadingOutlined} from "@ant-design/icons-vue";

export default {
  name: "LoaderSpinner",
  data() {
    return {
      indicator: createVNode(LoadingOutlined, {
        style: {
          fontSize: '64px',
          color: '#ffffff'
        },
        spin: true
      })
    }
  },
  mounted() {
    document.body.setAttribute('style', 'overflow:hidden');
  },
  unmounted() {
    document.body.removeAttribute('style');
  }
}
</script>

<template>
  <div class="loader">
    <a-spin :indicator="indicator" size="large">
    </a-spin>
  </div>
</template>

<style scoped>
.loader {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 10000;
}

.scroll-lock {
  overflow: hidden;
}
</style>