import {makeGetReq, makePostReq, makePutReq} from "../helpers/request";
import {defaultHttpResHandler} from "../helpers/defaultHttpResHandler";
import {appConfig} from "@/lib/appConfig";


// http request for login into system
export const login = async (data) => {
    return await defaultHttpResHandler(async () => {
        return await makePostReq(appConfig.domain + '/admin/login', data, {data: true});
    });
};

export const fetchUserData = async (token) => {
    return await defaultHttpResHandler(async () => await makeGetReq(appConfig.domain + '/admin', {token}))
}

export const updateAdminData = async (token, data) => {
    return await defaultHttpResHandler(async () => await makePutReq(appConfig.domain + '/admin', data, {
        token,
        data: true
    }));
}

export const createAdmin = async (token, data) => {
    return await defaultHttpResHandler(async () => await makePostReq(appConfig.domain + '/admin', data, {
        token,
        data: true
    }));
}

export const createLecture = async (token, data) => {
    return await defaultHttpResHandler(async () => await makePostReq(appConfig.domain + '/admin/lector', data, {
        token,
        data: true
    }));
}



export const downloadCSV = async (token) => {
    return await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/admin/discussion/csv`, {
        token,
        isBlob: true
    }));
}

