<template>
  <div class="search-users-container">
    <a-table class="table" :custom-row="customRow" :dataSource="usersList" :columns="columns">
      <template #bodyCell="{column,record}">
        <div class="status-tags" v-if="column.key==='status'">
          <a-tag color="success" v-if="record.status==='available'">Активный</a-tag>
          <a-tag color="processing" v-if="record.status==='unapproved'">Не подтвержден</a-tag>
          <a-tag color="error" v-if="record.status==='deleted'">Удален</a-tag>
          <a-tag color="warning" v-if="record.status==='banned'">В блокировке</a-tag>
          <a-tag color="cyan" v-if="record.status==='chat_blocked'">Чат заблокирован</a-tag>
        </div>
        <div class="" v-if="column.key==='registerDate'">
          {{ record.registerDate ? new Date(record.registerDate).toLocaleDateString('ru-RU', dateOptions) : '' }}
        </div>
        <div class="" v-if="column.key==='role'">
          {{
            record.role === 'lecture' ? 'Ведущий' : record.role === 'admin' ? 'Администратор' : record.role === 'guide' ? 'Экскурсовод' : record.role === 'exhibit' ? 'Экспонат' : 'Пользователь'
          }}
        </div>

      </template>
      <template #customFilterDropdown="{column,confirm}">
        <div class="filters-container" v-if="column.key==='registerDate'">
          <div class="filters-el">
            <h4>От: </h4>
            <a-date-picker :locale="locale" v-model:value="from"/>
          </div>
          <div class="filters-el">
            <h4>До: </h4>
            <a-date-picker :locale="locale" v-model:value="to"/>
          </div>
          <div class="filters-el">
            <h4>Сортировка по датам: </h4>
            <a-select placeholder="Сортировка" v-model:value="sort">
              <a-select-option value="dec">
                Сначала новые
              </a-select-option>
              <a-select-option value="inc">
                Сначала старые
              </a-select-option>
            </a-select>
          </div>

          <div class="filters-buttons">
            <a-button
                @click="fetchUsersList(confirm)"
                type="primary">
              Найти
            </a-button>
            <a-button @click="dropFilters(confirm)">
              Сбросить
            </a-button>
          </div>
        </div>
        <div class="filters-container" v-if="column.key==='name'">
          <div class="filters-el">
            <h4>Поиск по имени: </h4>
            <a-input v-model:value="searchName" placeholder="ФИО" @keydown.enter="fetchUsersList(confirm)"></a-input>
          </div>

          <div class="filters-buttons">
            <a-button
                @click="fetchUsersList(confirm)"
                type="primary">
              Найти
            </a-button>
            <a-button @click="dropFilters(confirm)">
              Сбросить
            </a-button>
          </div>
        </div>
      </template>

    </a-table>
  </div>
</template>

<script>
import locale from "ant-design-vue/es/date-picker/locale/ru_RU";
import dayjs from "dayjs";

export default {
  name: "SearchUser",
  props: ['role', 'existUsers'],
  computed: {
    usersList() {
      return this.$store.getters.usersList;
    }
  },
  data() {
    return {
      columns: [
        {
          title: 'ФИО',
          dataIndex: 'name',
          key: 'name',
          customFilterDropdown: true
        },
        {
          title: 'Номер телефона',
          dataIndex: 'phone',
          key: 'phone',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Роль',
          dataIndex: 'role',
          key: 'role',
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          key: 'status',
        },
        {
          title: 'Дата регистрации',
          dataIndex: 'registerDate',
          key: 'registerDate',
          customFilterDropdown: true
        },
      ],

      searchName: '',
      page: 1,
      limit: 500,
      to: null,
      from: null,
      sort: 'dec',
      locale,
      dayjs,

      dateOptions: {
        month: "long",
        day: "numeric",
        year: "numeric"
      },

    }
  },
  methods: {
    customRow(record) {
      const isExist = this.existUsers?.findIndex((item) => item?.clientId === record?.id) > -1;
      return {
        style: {
          cursor: isExist ? '' : 'pointer',
          pointerEvents: isExist ? 'none' : '',
          opacity: isExist ? '0.3' : ''
        },
        onclick: () => {
          this.$emit('addUser', record?.id);
        }
      }
    },

    dropFilters(confirm) {
      this.sort = 'dec';
      this.to = null;
      this.from = null;
      this.page = 1;
      this.searchName = null;
      this.fetchUsersList(confirm);
    },
    fetchUsersList(confirm) {
      this.$store.dispatch('fetchUsersList', {
        page: this.page,
        limit: this.limit,
        to: this.to ? this.dayjs(this.to).toDate().toUTCString() : '',
        from: this.from ? this.dayjs(this.from).toDate().toUTCString() : '',
        name: this.searchName ? this.searchName : '',
        role: this.role ? this.role : '',
        sort: this.sort
      }).then(() => {
        if (confirm) {
          confirm();
        }
      });
    },
  },

  beforeMount() {
    this.dayjs.locale('ru');
    this.fetchUsersList();
  },
  beforeUnmount() {
    this.$store.commit('clearTableList');
  }

}
</script>


<style scoped>

.search-users-container {
  margin: 15px;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
}

.filters-container {
  padding: 10px 10px 0 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filters-el {
  width: 170px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}


.filters-buttons {
  display: flex;
  gap: 15px;
  justify-content: space-between;
  margin: 10px 0;
}


</style>