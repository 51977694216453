import {defaultHttpResHandler} from "@/store/helpers/defaultHttpResHandler";
import {makeDeleteReq, makeGetReq, makePostReq, makePutReq} from "@/store/helpers/request";
import {appConfig} from "@/lib/appConfig";

export const fetchEvents = async (token, page, limit, type) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/event?page=${page}&limit=${limit}&type=${type}&id=`, {token}));

export const fetchAveleksFeedback = async (token, page, limit) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/feedback/aveleks?page=${page}&limit=${limit}`, {token}));

export const removeEventById = async (token, id) => await defaultHttpResHandler(async () => await makeDeleteReq(`${appConfig.domain}/event/${id}`, {token}));

export const fetchExhibitionPavilions = async (token, eventId) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/event/pavilions?eventId=${eventId}`, {token}));

export const deleteExhibitionPavilion = async (token, id) => await defaultHttpResHandler(async () => await makeDeleteReq(`${appConfig.domain}/event/pavilions?id=${id}`, null, {token}));

export const fetchCurrentEvent = async (token, id) => await defaultHttpResHandler(async () => await makeGetReq(`${appConfig.domain}/event?id=${id}`, {token}));

export const saveEvent = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/event`, data, {
    token,
    data: true
}));


export const saveExhibits = async (token, data) => await defaultHttpResHandler(async () => await makePostReq(`${appConfig.domain}/event/pavilions`, data, {
    token,
    data: true
}));

export const toggleDisableSlot = async (token, data) => {
    return await defaultHttpResHandler(async () => await makePutReq(appConfig.domain + '/event/toggle-slot', data, {
        token,
        data: true
    }));
}