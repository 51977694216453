<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider
        breakpoint="lg"
        collapsed-width="0"
    >
      <div class="logo">
        <img src="@/assets/animation-logo.svg" alt="">
      </div>
      <a-menu :selected-keys="[selectedKeys]" theme="dark" mode="inline">
        <a-menu-item key="users">
          <router-link class="nav-text" :to="{name:'users'}">Пользователи</router-link>
        </a-menu-item>
        <!--        <a-menu-item key="chats">-->
        <!--          <router-link class="nav-text" :to="{name:'chats'}">Чат пользователей</router-link>-->
        <!--        </a-menu-item>-->
        <a-menu-item key="settings">
          <router-link class="nav-text" :to="{name:'settings'}">Настройки</router-link>
        </a-menu-item>
        <a-menu-item key="eventsList">
          <router-link class="nav-text" :to="{name:'eventsList'}">Создание</router-link>
        </a-menu-item>
        <!-- <a-menu-item key="forms">
          <router-link class="nav-text" :to="{name:'forms'}">Обратная связь</router-link>
        </a-menu-item> -->
        <a-menu-item key="support">
          <router-link class="nav-text" :to="{name:'support'}">Обратная связь</router-link>
        </a-menu-item>
        <a-menu-item key="aveleks-feedback">
          <router-link class="nav-text" :to="{name:'aveleks-feedback'}">Обратная связь (Ваня)</router-link>
        </a-menu-item>
        <a-menu-item>
          <a-button ghost danger @click="$store.dispatch('logout')">Выйти</a-button>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="background: #fff; height: 70px">
        <h2 style="display: flex;align-items: center">
          {{ headerTitle }}
        </h2>
      </a-layout-header>
      <a-layout-content :style="{ margin: '24px 16px 0' }">
        <slot name="content"></slot>
      </a-layout-content>
      <a-layout-footer v-if="false" style="text-align: center">
        Ant Design ©2018 Created by Ant UED
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
export default {
  props: ['selectedKeys'],
  name: "MenuComponent",
  data() {
    return {
      headerTitle: this.selectedKeys === 'separateUser' ? 'Анкета пользователя' : this.selectedKeys === 'settings' ? 'Настройки' : this.selectedKeys === 'users' ? 'Пользователи' /*: this.selectedKeys === 'forms' ? 'Обратная связь'*/ : this.selectedKeys === 'eventsList' ? 'Список мероприятий' : this.selectedKeys === 'support' ? 'Обратная связь' : this.selectedKeys === 'aveleks-feedback' ? 'Обратная связь (Ваня)' : this.selectedKeys === 'eventEditor' ? (this.$route.params.id === 'new' ? 'Создание' : 'Редактирование') : ''
    }
  }
}
</script>

<style scoped>
.logo {
  margin: 10px;
  height: 60px;
  display: flex;
  justify-content: center;
}

</style>