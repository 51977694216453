<template>
  <div class="dark-container">
    <div class="dark-auth-form-container">
      <div class="dark-auth-form__wrapper">
        <img src="@/assets/animation-logo.svg" alt="" class="logo">
        <h1 class="dark-auth-form-title">Админ панель МетаМосква</h1>
        <div class="input-form">
          <a-input @keydown.enter="authorization" class="input-form__input" placeholder="Логин" v-model:value="login"
                   type="text"/>
          <a-input-password @keydown.enter="authorization" class="input-form__input" placeholder="Пароль"
                            v-model:value="password"/>
        </div>
        <a-button @click="authorization" type="primary" class="submit">Войти</a-button>

      </div>
    </div>
  </div>
</template>


<script>

export default {
  name: "AuthorizationPage",
  data() {
    return {
      login: null,
      password: null
    }
  },
  methods: {
    authorization() {
      this.$store.dispatch('login', {login: this.login, password: this.password});
    }
  }
};
</script>

<style scoped>
.dark-container {
  background-color: rgb(45, 58, 75);
}

.dark-auth-form-container {
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dark-auth-form__wrapper {
  margin: auto;
  text-align: center;
  max-width: 520px;
  width: 100%;
  box-sizing: border-box;
}
.logo{
  margin: 0 auto;
  height: 6rem;
}

.dark-auth-form-title {
  font-size: 32px;
  line-height: 1.5em;
  color: #eee;
  margin: 12px auto 40px auto;
  text-align: center;
  font-weight: 700;
}

.input-form {
  font-size: 16px;
}

.input-form__input {
  margin-bottom: 20px;
  font-size: 16px;
}

.submit {
  height: 40px;
}
</style>